import React from 'react'
import { Avatar, Button, Input, List, Tag } from 'antd'
import { UserOutlined, ToolOutlined } from '@ant-design/icons'

import { CustomListItem, CustomSpin, FlexContainer, GridContainer } from 'styles/styles'
import { UsersPropType } from 'util/types/props.types'
import { User } from 'util/types/generics.types'
import withLayout from 'hocs/withLayout'

const Users: React.FC<UsersPropType> = ({ users, synchingUsers, onSearchUser, onSyncUsers, onUserSelected }) => { 
    const renderUser = (user: User) => {
        const names = user.name.split(' ')
        
        return (
            <CustomListItem
                key={user.id}
                onClick={() => onUserSelected(user)}
                count={user.devicesCount}
            >
                <List.Item.Meta 
                    avatar={<Avatar>{names[0][0]}{names[1][0]}</Avatar>}
                    title={user.name}
                    description={user.email}
                />
                <Tag 
                    color={user.role === 'admin' ? 'green' : 'red'}
                    icon={user.role === 'admin' ? <ToolOutlined /> : <UserOutlined />}
                >
                    {user.role}
                </Tag>
            </CustomListItem>
        )
    }
    
    const renderSearchAndSync = () => (
        <FlexContainer
            direction='column'
            justify='center'
            align='center'
            rowGap='1em'
        >
            <Input
                onChange={e => onSearchUser(e)}
                placeholder='Search User'
                style={{ width: '50%' }}
            />
            <Button
                type='primary'
                onClick={onSyncUsers}
            >
                Sync Users
            </Button>
        </FlexContainer>
    )

    return (
        <GridContainer
            rows='auto'
            rowGap='1em'
        >
            {renderSearchAndSync()}
            {
                synchingUsers ? 
                    <CustomSpin size='large'/> : 
                    <List
                        dataSource={users}
                        renderItem={user => renderUser(user)}
                    />
            }

        </GridContainer>
    )
}

export default withLayout(Users)