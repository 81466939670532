import React from "react";
import {
  Button,
  Descriptions,
  DatePicker,
  Input,
  Select,
  Form,
  InputNumber,
  Row,
  Col,
  Tag,
} from "antd";
import { DEVICE_STATES, DEVICE_TYPES, USER_ROLES } from "util/constants";
import { AvailabilityStatus, DefaultVSPButton } from "styles/styles";

import AssignDevice from "components/forms/AssignDevice";
import ReturnDevice from "components/forms/ReturnDevice";
import DeviceHistory from "components/histories/DeviceHistory";
import { DevicePropType } from "util/types/props.types";
import withLayout from "hocs/withLayout";

const Device: React.FC<DevicePropType> = ({
  userRole,
  device,
  deviceType,
  formData,
  formVisibility,
  showAssignDeviceForm,
  showReturnDeviceForm,
  onChange,
  onDeviceTypeChange,
  onFinish,
  onDeleteDevice,
  onDeviceAvailabilityChange,
}) => {
  const editDenyed = userRole === USER_ROLES.ADMIN ? false : true;
  const initialValues = { ...device };

  const renderName = () => (
    <Descriptions.Item label="Name">
      <Form.Item name="name" rules={[{ required: true }]}>
        <Input bordered={!editDenyed} readOnly={editDenyed} />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderSerialNumber = () => (
    <Descriptions.Item label="Serial Number">
      <Form.Item name="serial_number" rules={[{ required: true }]}>
        <Input bordered={!editDenyed} readOnly={editDenyed} />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderType = () => (
    <Descriptions.Item label="Type">
      <Form.Item name="type">
        <Select
          onChange={(value: string) => onDeviceTypeChange(value)}
          bordered={!editDenyed}
          options={DEVICE_TYPES}
          disabled={editDenyed}
        />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderOtherType = () => {
    return (
      deviceType === "other" && (
        <Descriptions.Item label="Other Type">
          <Form.Item name="other_type" rules={[{ required: true }]}>
            <Input bordered={!editDenyed} readOnly={editDenyed} />
          </Form.Item>
        </Descriptions.Item>
      )
    );
  };

  const renderState = () => {
    const state = DEVICE_STATES.find(
      (deviceState) => deviceState.value === device.status
    );
    return (
      <Descriptions.Item label="Status">
        <Tag color={state?.color}>{state?.value}</Tag>
      </Descriptions.Item>
    );
  };

  const renderAvailabilityStatus = () => (
    <Descriptions.Item label="Working state">
      <AvailabilityStatus
        available={!device.is_broken}
        baseColor="#63A54E"
        uppercase
        emphasise
      >
        {device.is_broken ? "Broken" : "Working"}
      </AvailabilityStatus>
    </Descriptions.Item>
  );

  const renderPrice = () => (
    <Descriptions.Item label="Price">
      {/* set the price required to false for DEMO */}
      <Form.Item name="price" rules={[{ required: false }]}>
        <InputNumber
          style={{ width: "100%" }}
          step={0.01}
          min={0.01}
          bordered={!editDenyed}
          readOnly={editDenyed}
        />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderPurchaseDate = () => (
    <Descriptions.Item label="Purchase Date">
      <Form.Item name="purchase_date" rules={[{ required: true }]}>
        <DatePicker
          bordered={!editDenyed}
          style={{ width: "100%" }}
          disabled={editDenyed}
        />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderAssignedTo = () => {
    return (
      device.owner && (
        <Descriptions.Item label="Assigned To">
          {device.owner}
        </Descriptions.Item>
      )
    );
  };

  const renderReturnDate = () => {
    return device.return_date && !device.owner ? (
      <Descriptions.Item label="Return Date">
        {device.return_date.format("YYYY-DD-MM")}
      </Descriptions.Item>
    ) : null;
  };

  const renderOS = () => (
    <Descriptions.Item label="Operating System">
      <Form.Item name="os">
        <Input
          bordered={!editDenyed}
          readOnly={editDenyed}
          placeholder="Enter a value for OS (Optional)"
        />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderRAM = () => (
    <Descriptions.Item label="RAM">
      <Form.Item name="ram">
        <InputNumber
          style={{ width: "100%" }}
          min={1}
          bordered={!editDenyed}
          readOnly={editDenyed}
          placeholder="Enter a value for RAM (Optional)"
        />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderCPU = () => (
    <Descriptions.Item label="CPU">
      <Form.Item name="cpu">
        <Input
          bordered={!editDenyed}
          readOnly={editDenyed}
          placeholder="Enter a value for CPU (Optional)"
        />
      </Form.Item>
    </Descriptions.Item>
  );

  const renderActionBar = () => {
    return (
      !editDenyed && (
        <Row gutter={[16, 16]}>
          <Col>
            <DefaultVSPButton
              type="primary"
              htmlType="submit"
              disabled={device.confirmation_pending}
            >
              Save
            </DefaultVSPButton>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="button"
              onClick={onDeleteDevice}
              disabled={device.confirmation_pending}
              danger
            >
              Delete
            </Button>
          </Col>
          <Col>
            {device.user_id !== null ? (
              <Button
                htmlType="button"
                onClick={() => showReturnDeviceForm(device)}
                disabled={device.confirmation_pending}
              >
                Return
              </Button>
            ) : (
              <Button
                htmlType="button"
                onClick={() => showAssignDeviceForm(device)}
                disabled={device.confirmation_pending}
              >
                Assign
              </Button>
            )}
          </Col>
          <Col>
            <Button
              type={!device.is_broken ? "ghost" : "primary"}
              htmlType="button"
              onClick={onDeviceAvailabilityChange}
              disabled={device.confirmation_pending}
              danger={!device.is_broken}
            >
              {device.is_broken ? "Mark Available" : "Mark Broken"}
            </Button>
          </Col>
        </Row>
      )
    );
  };

  const renderReadonlyInformation = () => (
    <Descriptions
      labelStyle={{
        fontWeight: "bold",
        fontSize: "1.4em",
        textTransform: "uppercase",
      }}
      layout="vertical"
      size="middle"
      bordered
    >
      {renderState()}
      {renderReturnDate()}
      {renderAssignedTo()}
      {renderAvailabilityStatus()}
    </Descriptions>
  );

  const renderMainInformation = () => (
    <Descriptions
      labelStyle={{
        fontWeight: "bold",
        fontSize: "1.4em",
        textTransform: "uppercase",
      }}
      layout="vertical"
      size="middle"
      bordered
    >
      {renderName()}
      {renderSerialNumber()}
      {renderPurchaseDate()}
      {renderType()}
      {renderOtherType()}
      {renderPrice()}
    </Descriptions>
  );

  const renderExtraSpecs = () => (
    <Descriptions
      labelStyle={{
        fontWeight: "bold",
        fontSize: "1.4em",
        textTransform: "uppercase",
      }}
      layout="vertical"
      bordered
    >
      {renderOS()}
      {renderRAM()}
      {renderCPU()}
    </Descriptions>
  );

  const renderDeviceHistory = () => {
    return <DeviceHistory id={device.id} />;
  };

  const renderRetrunDeviceModal = () => (
    <ReturnDevice
      visible={formVisibility.return}
      closeForm={() => showReturnDeviceForm(device)}
    />
  );

  const renderAssignDeviceModal = () => (
    <AssignDevice
      visible={formVisibility.assign}
      closeForm={() => showAssignDeviceForm(device)}
    />
  );

  return (
    <>
      <Form
        initialValues={initialValues}
        form={formData}
        onFinish={onFinish}
        onValuesChange={onChange}
        style={{ display: "flex", flexDirection: "column", rowGap: "3em" }}
      >
        {renderActionBar()}
        {renderReadonlyInformation()}
        {renderMainInformation()}
        {renderExtraSpecs()}
      </Form>
      {renderDeviceHistory()}
      {renderRetrunDeviceModal()}
      {renderAssignDeviceModal()}
    </>
  );
};

export default withLayout(Device);
