import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'

import confirmationService from 'services/ConfirmationService'
import ConfirmationDialog from './ConfirmationDialog'
import { LOGIN_PATH } from 'util/paths'
import { NeedsAuthenticationDialogPropType } from 'util/types/props.types'

const NeedsAuthenticationDialog: React.FC<NeedsAuthenticationDialogPropType> = ({ confirmationPath }) => { 
    const { token } = useParams()
    const navigate = useNavigate()
    
    useEffect(() => {
        confirmationService.setConfirmationToken(token as string)
        confirmationService.setConfirmationPath(confirmationPath)
    }, [token, confirmationPath])

    return (
        <ConfirmationDialog 
            status='info'
            title='Please login to confirm action'
            extra={[
                <Button 
                    type='primary'
                    size='large'
                    onClick={() => navigate(LOGIN_PATH)}
                    key={1}
                >   
                    GO TO LOGIN
                </Button>
            ]}
        />
    )
}

export default NeedsAuthenticationDialog